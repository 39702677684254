import "@hotwired/turbo-rails"

import "@asgerb/apparatus/application"

import "~/controllers"

Turbo.FrameElement.delegateConstructor.prototype.proposeVisitIfNavigatedWithAction =
  function (frame, action = null) {
    this.action = action

    if (this.action) {
      const pageSnapshot = Turbo.PageSnapshot.fromElement(frame).clone()
      const { visitCachedSnapshot } = frame.delegate

      frame.delegate.fetchResponseLoaded = (fetchResponse) => {
        if (frame.src) {
          const { statusCode, redirected } = fetchResponse
          const responseHTML = frame.ownerDocument.documentElement.outerHTML
          const response = { statusCode, redirected, responseHTML }
          const options = {
            response,
            visitCachedSnapshot,
            willRender: false,
            updateHistory: false,
            restorationIdentifier: this.restorationIdentifier,
            snapshot: pageSnapshot
          }

          if (this.action) options.action = this.action

          Turbo.session.visit(frame.src, options)
        }
      }
    }
  }
