import { useClickOutside } from "stimulus-use"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { open: Boolean }
  static targets = ["item", "details", "current"]

  connect() {
    useClickOutside(this)
  }

  clickOutside() {
    this.close()
  }

  onRender(event) {
    const {
      detail: { url }
    } = event
    this.itemTargets.forEach((item) => {
      item.ariaCurrent =
        item.href === url || url.startsWith(item.href) ? "page" : null
    })
  }

  toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    this.element.dataset.open = this.openValue
  }

  close() {
    this.openValue = false
  }

  open() {
    this.openValue = true
  }
}
